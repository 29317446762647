$(document).ready(function() {

    //
    // Layout
    //

    // Zusatzlogos ausblenden, wenn die Metanavigation umbricht und die Zusatzlogos nach unten drückt
    function hideAdditionalLogoOnOverflow(){

        var DesktopMetanavWrapper = $('#desktopMetanavWrapper');
        var DesktopMetanavWrapperChild = DesktopMetanavWrapper.find('li').first();
        var ZusatsLogosWrapper = $('#additionalLogoWrapper');
        var MainLogoWrapper = $('#mainLogoWrapper');
        var MainLogo = MainLogoWrapper.find('img.logo__main').first();
        var MainLogoHeight = MainLogo.height();
        var MainLogoWrapperHeight = MainLogoWrapper.height();
        var DesktopMetanavWrapperHeight = DesktopMetanavWrapper.height();
        var DesktopMetanavWrapperChildHeight = DesktopMetanavWrapperChild.outerHeight(true);

        if(DesktopMetanavWrapper && DesktopMetanavWrapperChild){
            if(DesktopMetanavWrapperHeight > DesktopMetanavWrapperChildHeight){
                ZusatsLogosWrapper.addClass('hidden');
            }
            if(DesktopMetanavWrapperHeight <= DesktopMetanavWrapperChildHeight){
                ZusatsLogosWrapper.removeClass('hidden');
            }
        }

    }
    hideAdditionalLogoOnOverflow();
    $(window).on('resize', function () {
        hideAdditionalLogoOnOverflow();
    });

    //
    // Top Themen
    //
    function showTopThemesMobile(){

        var TTButton = $('#topthemenbutton');
        var TTButtonIcon = TTButton.find('i.fas').first();
        var TTPanel = $('#topthemes');
        var TTPanelFirstLink = TTPanel.find('a').first();

        TTButton.on("click", function(event) {

            // 32=Leertaste, 13=Return, 1=Linke Maustaste
            if (event.which == 32 || event.which == 13 || event.which == 1) {
                event.preventDefault();

                if(TTButton.attr('aria-expanded')=='false') {// Panel anzeigen

                    TTPanel.removeClass('hidden');
                    TTButton.attr('aria-expanded','true');

                    TTButtonIcon.removeClass('fa-plus');
                    TTButtonIcon.addClass('fa-times');

                    TTPanelFirstLink.focus();

                    setMainPadding();

                }else{// Panel ausblenden

                    TTPanel.addClass('hidden');
                    TTButton.attr('aria-expanded','false');

                    TTButtonIcon.removeClass('fa-times');
                    TTButtonIcon.addClass('fa-plus');

                    setMainPadding();

                }

            }

        });

    }
    showTopThemesMobile();

    //
    // Breadcrumb
    //
    function disableBreadcrumbCurrentPage(){
        $('#breadcrumbCurrentPage').on("click", function(event) {
            event.preventDefault();
        });
    }
    disableBreadcrumbCurrentPage();

    //
    // Ebenennavigation
    //
    // rechte Marginalspalte
    function showSubMenuRechts(){

        $( ".ebenennavigationRechtsLayerButton" ).each(function() {

            $(this).on("click", function(event) {
                // 32=Leertaste, 13=Return, 1=Linke Maustaste
                if (event.which == 32 || event.which == 13 || event.which == 1) {
                    event.preventDefault();

                    var Panel = $('#' + $(this).attr('aria-controls'));
                    var PanelFirstLink = Panel.find('a').first();
                    var Button = $(this);
                    var ButtonIcon = Button.find('i.ebenennavigation-openclose-icon').first();
                    var Layer = Button.attr('data-level');
                    var LayerClass = Button.attr('data-level-class');
                    //console.log(Layer);

                    if(Button.attr('aria-expanded')=='false') {// Panel anzeigen

                        closeMainMenu();
                        closeSearchPanel();
                        closeAllMenuPanelsSameLayer(Layer,LayerClass);

                        Panel.removeClass('hidden');
                        Button.attr('aria-expanded','true');

                        ButtonIcon.removeClass('fa-plus');
                        ButtonIcon.addClass('fa-times');

                        PanelFirstLink.focus();

                    }else{// Panel ausblenden

                        closeMainMenu();
                        closeSearchPanel();
                        closeAllMenuPanelsLowerLayers(Layer,LayerClass);

                        Panel.addClass('hidden');
                        Button.attr('aria-expanded','false');

                        ButtonIcon.removeClass('fa-times');
                        ButtonIcon.addClass('fa-plus');

                    }

                }
            });

        });

    }
    showSubMenuRechts();

    //
    // Ebenennavigation
    //
    // untere Marginalspalte
    function showSubMenuPieces(){

        $( ".ebenennavigationLayer4Button" ).each(function() {

            $(this).on("click", function(event) {
                // 32=Leertaste, 13=Return, 1=Linke Maustaste
                if (event.which == 32 || event.which == 13 || event.which == 1) {
                    event.preventDefault();

                    var Panel = $('#' + $(this).attr('aria-controls'));
                    var PanelFirstLink = Panel.find('a').first();
                    var Button = $(this);
                    var ButtonIcon = Button.find('i.fas').first();

                    if(Button.attr('aria-expanded')=='false') {// Panel anzeigen

                        closeMainMenu();
                        closeSearchPanel();
                        closeAllSubMenuPanels();

                        Panel.removeClass('hidden');
                        Button.attr('aria-expanded','true');

                        ButtonIcon.removeClass('fa-plus');
                        ButtonIcon.addClass('fa-times');

                        PanelFirstLink.focus();

                    }else{// Panel ausblenden

                        closeMainMenu();
                        closeSearchPanel();

                        Panel.addClass('hidden');
                        Button.attr('aria-expanded','false');

                        ButtonIcon.removeClass('fa-times');
                        ButtonIcon.addClass('fa-plus');

                    }

                }
            });

        });

    }
    showSubMenuPieces();

    // Alle Panels der Unternavigation schließen
    function closeAllSubMenuPanels(){

        var Panels = $('.ebenennavigationLayer4Panel');
        var Buttons = $('.ebenennavigationLayer4Button');

        Buttons.each(function() {
            var ButtonsIcons = Buttons.find('i.ebenennavigation-openclose-icon');
            ButtonsIcons.removeClass('fa-times');
            ButtonsIcons.addClass('fa-plus');
        });

        Panels.addClass('hidden');
        Buttons.attr('aria-expanded','false');

    }

    /**
     * Alle Panels der gleichen Ebene schließen
     *
     * @param {number} Layer - Die Ebene, auf der sich die Panels und Buttons befinden.
     * @param {string} LayerClass - The class name of the layer containing the panels and buttons.
     * @returns {void}
     */
    function closeAllMenuPanelsSameLayer(Layer,LayerClass){

        //console.log(Layer);
        var Panels = $('ul.' + LayerClass);
        var Buttons = $('button.' + LayerClass);

        Buttons.each(function() {
            var ButtonsIcons = Buttons.find('i.ebenennavigation-openclose-icon');
            ButtonsIcons.removeClass('fa-times');
            ButtonsIcons.addClass('fa-plus');
        });

        Panels.addClass('hidden');
        Buttons.attr('aria-expanded','false');

        closeAllMenuPanelsLowerLayers(Layer,LayerClass);

    }

    /**
     * Schließt alle Panels, die sich auf niedrigeren Ebenen als der angegebenen Ebene befinden.
     *
     * @param {number} Layer - The layer at or above which the menu panels should not be closed.
     * @param {string} LayerClass - The class name used to identify the menu panels and buttons.
     * @returns {undefined}
     */
    function closeAllMenuPanelsLowerLayers(Layer,LayerClass){

        for(var i=1; i<6; i++){

            var Panels = $('ul.subnav-right-data-level-' + i);
            var Buttons = $('button.subnav-right-data-level-' + i);

            if(i > Layer){
                Buttons.each(function() {
                    var ButtonsIcons = Buttons.find('i.ebenennavigation-openclose-icon');
                    ButtonsIcons.removeClass('fa-times');
                    ButtonsIcons.addClass('fa-plus');
                });
                Panels.addClass('hidden');
                Buttons.attr('aria-expanded','false');
            }

        }

    }

    //
    // Hauptmenüs
    //

    // Hauptmenü Ein-/Ausblenden
    function showMainMenu(){

        var MainNavigationWrapper = $('#mainNavigationWrapper');
        var FirstNavLink = MainNavigationWrapper.find('a').first();
        var LastPanelTabLink = MainNavigationWrapper.find('a').last();
        var MainNavigationButton = $('#mainNavigationButton');
        var MainNavigationButtonOpen = $('#mainMenuOpen');
        var MainNavigationButtonClose = $('#mainMenuClose');
        var SearchPanelButton = $('#searchPanelButton');

        MainNavigationButton.on("click", function(event) {
            // 32=Leertaste, 13=Return, 1=Linke Maustaste
            if (event.which == 32 || event.which == 13 || event.which == 1) {
                event.preventDefault();

                if(MainNavigationButton.attr('aria-expanded')=='false'){// Menu anzeigen

                    closeSearchPanel();

                    MainNavigationWrapper.removeClass('hidden');
                    MainNavigationButton.attr('aria-expanded','true');

                    limitPanelHeightOnBrowserBottom('#mainNavigationWrapper');

                    MainNavigationButtonOpen.addClass('hidden');
                    MainNavigationButtonClose.removeClass('hidden');
                    stopPageScrolling();// Scrolling stoppen
                    if(FirstNavLink){
                        FirstNavLink.focus();
                    }
                    MainNavigationButton.removeAttr('tabindex');
                    SearchPanelButton.attr('tabindex','-1');
                }else{// Menu ausblenden
                    MainNavigationWrapper.removeAttr('style');
                    MainNavigationWrapper.addClass('hidden');
                    MainNavigationButton.attr('aria-expanded','false');
                    MainNavigationButtonOpen.removeClass('hidden');
                    MainNavigationButtonClose.addClass('hidden');
                    restorePageScrolling();// Scrolling freigeben
                    MainNavigationButton.focus();
                    SearchPanelButton.removeAttr('tabindex');
                    MainNavigationButton.addClass('bund-focus-outline');
                    MainNavigationButton.removeClass('bund-focus-outline-none');
                }
            }
        });

        MainNavigationButton.on("focusin", function(event) {

            // Menü offen
            if(MainNavigationButton.attr('aria-expanded') == 'true'){
                MainNavigationButton.removeClass('bund-focus-outline');
                MainNavigationButton.addClass('bund-focus-outline-none');
                MainNavigationButtonClose.find('div').first().addClass('bund-outline');
                MainNavigationButtonClose.find('div').first().next().addClass('bund-outline');
            }
            // Menü geschlossen
            if(MainNavigationButton.attr('aria-expanded') == 'false'){
                MainNavigationButton.addClass('bund-focus-outline');
                MainNavigationButton.removeClass('bund-focus-outline-none');
                MainNavigationButtonClose.find('div').first().removeClass('bund-outline');
                MainNavigationButtonClose.find('div').first().next().removeClass('bund-outline');
            }

        });

        MainNavigationButton.on("focusout", function(event) {

            MainNavigationButtonClose.find('div').first().removeClass('bund-outline');
            MainNavigationButtonClose.find('div').first().next().removeClass('bund-outline');

        });

        // Tab zurück
        MainNavigationButton.keydown(function(eventbacktab) {
            if (eventbacktab.shiftKey && eventbacktab.keyCode == 9) {
                if(MainNavigationButton.attr('aria-expanded') == 'true'){
                    closeMainMenu();
                    SearchPanelButton.removeAttr('tabindex');
                    restorePageScrolling();// Scrolling freigeben
                }
            }
        });

        // Tab vor, letztes Tab-Element im Panel
        LastPanelTabLink.keydown(function(eventforwardtab) {
            if (!eventforwardtab.shiftKey && eventforwardtab.keyCode == 9) {
                eventforwardtab.preventDefault();
                closeMainMenu();
                SearchPanelButton.removeAttr('tabindex');
                SearchPanelButton.focus();
                restorePageScrolling();// Scrolling freigeben
            }
        });

    }
    showMainMenu();

    // Hauptmenü schließen
    function closeMainMenu(){

        var MainNavigationWrapper = $('#mainNavigationWrapper');
        var MainNavigationButton = $('#mainNavigationButton');
        var MainNavigationButtonOpen = $('#mainMenuOpen');
        var MainNavigationButtonClose = $('#mainMenuClose');

        MainNavigationWrapper.removeAttr('style');//
        MainNavigationWrapper.addClass('hidden');
        MainNavigationButton.attr('aria-expanded','false');
        MainNavigationButtonOpen.removeClass('hidden');
        MainNavigationButtonClose.addClass('hidden');
        MainNavigationButton.addClass('bund-focus-outline');
        MainNavigationButton.removeClass('bund-focus-outline-none');

    }

    // Suche-Panel Ein-/Ausblenden
    function showSearchPanel(){

        var SearchPanelWrapper = $('#searchPanelWrapper');
        var SearchInput = SearchPanelWrapper.find('input#topsearch-field').first();
        var LastPanelTabLink = SearchPanelWrapper.find('form#topsearch .jquery__topSearchField').last();
        var SearchPanelButton = $('#searchPanelButton');
        var SearchPanelButtonOpen = $('#searchPanelOpen');
        var SearchPanelButtonClose = $('#searchPanelClose');
        var MainNavigationButton = $('#mainNavigationButton');

        SearchPanelButton.on("click", function(event) {
            // 32=Leertaste, 13=Return, 1=Linke Maustaste
            if (event.which == 32 || event.which == 13 || event.which == 1) {
                event.preventDefault();

                if(SearchPanelButton.attr('aria-expanded')=='false'){// Menu anzeigen

                    closeMainMenu();

                    SearchPanelWrapper.removeClass('hidden');
                    SearchPanelButton.attr('aria-expanded','true');

                    limitPanelHeightOnBrowserBottom('#searchPanelWrapper');

                    SearchPanelButtonOpen.addClass('hidden');
                    SearchPanelButtonClose.removeClass('hidden');
                    stopPageScrolling();// Scrolling stoppen
                    SearchPanelButton.removeAttr('tabindex');
                    MainNavigationButton.attr('tabindex','-1');
                    SearchInput.focus();
                    document.getElementById("topsearch-field").focus();
                }else{// Menu ausblenden
                    SearchPanelWrapper.removeAttr('style');
                    SearchPanelWrapper.addClass('hidden');
                    SearchPanelButton.attr('aria-expanded','false');
                    SearchPanelButtonOpen.removeClass('hidden');
                    SearchPanelButtonClose.addClass('hidden');
                    restorePageScrolling();// Scrolling freigeben
                    SearchPanelButton.focus();
                    MainNavigationButton.removeAttr('tabindex');
                    SearchPanelButton.addClass('bund-focus-outline');
                    SearchPanelButton.removeClass('bund-focus-outline-none');
                }
            }
        });

        SearchPanelButton.on("focusin", function(event) {

            // Menü offen
            if(SearchPanelButton.attr('aria-expanded') == 'true'){
                SearchPanelButton.removeClass('bund-focus-outline');
                SearchPanelButton.addClass('bund-focus-outline-none');
                SearchPanelButtonClose.find('div').first().addClass('bund-outline');
                SearchPanelButtonClose.find('div').first().next().addClass('bund-outline');
            }
            // Menü geschlossen
            if(SearchPanelButton.attr('aria-expanded') == 'false'){
                SearchPanelButton.addClass('bund-focus-outline');
                SearchPanelButton.removeClass('bund-focus-outline-none');
                SearchPanelButtonClose.find('div').first().removeClass('bund-outline');
                SearchPanelButtonClose.find('div').first().next().removeClass('bund-outline');
            }

        });

        SearchPanelButton.on("focusout", function(event) {

            SearchPanelButtonClose.find('div').first().removeClass('bund-outline');
            SearchPanelButtonClose.find('div').first().next().removeClass('bund-outline');

        });

        // Tab zurück
        SearchPanelButton.keydown(function(eventbacktab) {
            if (eventbacktab.shiftKey && eventbacktab.keyCode == 9) {
                if(SearchPanelButton.attr('aria-expanded') == 'true'){
                    closeSearchPanel();
                    MainNavigationButton.removeAttr('tabindex');
                    restorePageScrolling();// Scrolling freigeben
                }
            }
        });

        // Tab vor, letztes Tab-Element im Panel
        LastPanelTabLink.keydown(function(eventforwardtab) {
            if (!eventforwardtab.shiftKey && eventforwardtab.keyCode == 9) {
                closeSearchPanel();
                MainNavigationButton.removeAttr('tabindex');
                restorePageScrolling();// Scrolling freigeben
            }
        });

    }
    showSearchPanel();

    // Suche-Panel schließen
    function closeSearchPanel(){

        var SearchPanelWrapper = $('#searchPanelWrapper');
        var SearchPanelButton = $('#searchPanelButton');
        var SearchPanelButtonOpen = $('#searchPanelOpen');
        var SearchPanelButtonClose = $('#searchPanelClose');

        SearchPanelWrapper.removeAttr('style');
        SearchPanelWrapper.addClass('hidden');
        SearchPanelButton.attr('aria-expanded','false');
        SearchPanelButtonOpen.removeClass('hidden');
        SearchPanelButtonClose.addClass('hidden');
        SearchPanelButton.addClass('bund-focus-outline');
        SearchPanelButton.removeClass('bund-focus-outline-none');

    }

    // Panel-Höhe auf Unterkante des Browserfensters begrenzen
    function limitPanelHeightOnBrowserBottom(panel){

        var MainNavigationWrapper = $(panel);

        MainNavigationWrapper.removeAttr('style');

        var MainNavigationWrapperHeight = MainNavigationWrapper.outerHeight(true);
        var MainNavigationWrapperPosY = MainNavigationWrapper.offset().top - $(window)['scrollTop']();
        var MainNavigationWrapperFullHeight = MainNavigationWrapperHeight + MainNavigationWrapperPosY;
        var BrowserViewPortHeight = $(window).height();
        var Padding = 40;
        var LimitHeight = ((BrowserViewPortHeight - MainNavigationWrapperPosY) - Padding);

        if(MainNavigationWrapperFullHeight > BrowserViewPortHeight){
            MainNavigationWrapper.height(LimitHeight);
        }else{
            MainNavigationWrapper.removeAttr('style');
        }

    }
    $(window).on('resize', function () {
        limitPanelHeightOnBrowserBottom('#mainNavigationWrapper');
        limitPanelHeightOnBrowserBottom('#searchPanelWrapper');
    });

    // Haupt- und Suchemenü mit ESC-Taste schließen
    function closeMainPanelsWithEsc(){
        $(document).keydown(function(eventa) {

            var MainNavigationButton = $('#mainNavigationButton');
            var SearchPanelButton = $('#searchPanelButton');

            // 27=ESC
            if (eventa.keyCode == 27) {

                //Hauptmenü
                if(MainNavigationButton.attr('aria-expanded') == 'true'){
                    closeMainMenu();
                    SearchPanelButton.removeAttr('tabindex');
                    MainNavigationButton.focus();
                    restorePageScrolling();// Scrolling freigeben
                }

                //Suche
                if(SearchPanelButton.attr('aria-expanded') == 'true'){
                    closeSearchPanel();
                    MainNavigationButton.removeAttr('tabindex');
                    SearchPanelButton.focus();
                    restorePageScrolling();// Scrolling freigeben
                }

            }
        });
    }
    closeMainPanelsWithEsc();

    // Haupt- und Suchemenü mit Mausclick außerhalp der Panels schließen
    function closeMainPanelsWithMouseclickOutsideThePanels(){

        $(document).mousedown(function(e) {

            var MainNavigationButton = $('#mainNavigationButton');
            var MainNavigationCloseButton = $('div#mainMenuClose > div').first();
            var MainNavigationWrapper = $('div#mainNavigationWrapper');

            var SearchPanelButton = $('#searchPanelButton');
            var SearchPanelCloseButton = $('div#searchPanelClose > div').first();
            var SearchPanelWrapper = $('div#searchPanelWrapper');

            //1 = Left   mouse button
            //2 = Centre mouse button
            //3 = Right  mouse button

            if (e.which === 1) {
                //console.log('click left');

                //Hauptmenü
                if(MainNavigationButton.attr('aria-expanded') == 'true'){

                    var PanelOrCloseButtonClicked = 0;

                    if(MainNavigationWrapper.is(':hover') == true){
                        PanelOrCloseButtonClicked = 1;
                    }
                    if(MainNavigationCloseButton.is(':hover') == true){
                        PanelOrCloseButtonClicked = 1;
                    }
                    if(MainNavigationButton.is(':hover') == true){
                        PanelOrCloseButtonClicked = 1;
                    }

                    if( PanelOrCloseButtonClicked == 0 ) {

                        // Schließenprozedur
                        closeMainMenu();
                        SearchPanelButton.removeAttr('tabindex');
                        MainNavigationButton.focus();
                        restorePageScrolling();// Scrolling freigeben

                    }

                }

                //Suche
                if(SearchPanelButton.attr('aria-expanded') == 'true'){

                    var PanelOrCloseButtonClicked = 0;

                    if(SearchPanelWrapper.is(':hover') == true){
                        PanelOrCloseButtonClicked = 1;
                    }
                    if(SearchPanelCloseButton.is(':hover') == true){
                        PanelOrCloseButtonClicked = 1;
                    }
                    if(SearchPanelButton.is(':hover') == true){
                        PanelOrCloseButtonClicked = 1;
                    }

                    if( PanelOrCloseButtonClicked == 0 ) {

                        // Schließenprozedur
                        closeSearchPanel();
                        MainNavigationButton.removeAttr('tabindex');
                        SearchPanelButton.focus();
                        restorePageScrolling();// Scrolling freigeben

                    }

                }

            }

        });

    }
    closeMainPanelsWithMouseclickOutsideThePanels();

    // Toggle Page-Scroll
    function stopPageScrolling(){
        /*
        // Get the current page scroll position
        scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,

        // if any scroll is attempted, set this to the previous value
        window.onscroll = function() {
            window.scrollTo(scrollLeft, scrollTop);
        };
        */

        var Body = $('body');

        Body.addClass('overflow-y-hidden');
    }
    function restorePageScrolling(){
        /*
        window.onscroll = function() {};
        */

        var Body = $('body');

        Body.removeClass('overflow-y-hidden');
    }

    //
    // Sticky Header
    //

    // Main-Padding an die Höhe des Header anpassen
    function setMainPadding(){

        var HeaderHeight = $('header').outerHeight(true);
        var Padding = 0;
        var MainPadding = HeaderHeight + Padding;
        var Main = $('main');

        Main.css('padding-top',MainPadding + 'px');

    }
    setMainPadding();
    $(window).on('resize', function () {
        setMainPadding();
    });

    //
    // Anpassungen für den Druck
    //
    window.onbeforeprint = (event) => {
        // console.log('Before print');
        var main = $('main');
        main.css('padding-top','0px');

        // Telefonbuch
        var telefonliste = $('table#telefonliste');
        telefonliste.parent().css('overflow','hidden');
        telefonliste.find('a').css('color','#000');
        telefonliste.find('a').css('text-decoration','none');
        telefonliste.find('a').find('span').css('color','#000');
        telefonliste.find('.text-bundFarben-fehlermeldungsrot').css('color','#000');
        telefonliste.find('span.inline-block').css('color','#000');
        telefonliste.find('span.inline-block').css('border-color','#000');

        // 2spaltiger Container mit Unternavigation rechts
        var container2colplussubnav = $('div[data-identifier=rightMarginCol]');
        container2colplussubnav.css('display','none');
    };
    window.onafterprint = (event) => {
        // Telefonbuch
        var telefonliste = $('table#telefonliste');
        telefonliste.parent().removeAttr('style');
        telefonliste.find('a').removeAttr('style');
        telefonliste.find('a').find('span').removeAttr('style');
        telefonliste.find('.text-bundFarben-fehlermeldungsrot').removeAttr('style');
        telefonliste.find('span.inline-block').removeAttr('style');

        // 2spaltiger Container mit Unternavigation rechts
        var container2colplussubnav = $('div[data-identifier=rightMarginCol]');
        container2colplussubnav.removeAttr('style');
    };

    // Header entsprechend der Scrollposition anpassen
    function stickyHeader(){

        var Header = $('body > header');
        var BrowserDocumentScrollY = $(window).scrollTop();
        var ZusatsLogosWrapperDiv = $('#additionalLogoWrapper > div');
        var TopThemen = $('#topThemen');
        var MainLogo = $('#mainLogoWrapper').find('img.logo__main').first();
        var MainLogoSticky = $('#mainLogoWrapper').find('img.logo__sticky').first();
        var MainLogoParent = $('#mainLogoWrapper').parent().parent();
        var MobileLogo = $('#mobileLogoWrapper').find('img.logo__main').first();
        var MobileLogoSticky = $('#mobileLogoWrapper').find('img.logo__sticky').first();
        var Borderline = $('#borderline');

        // Mobil
        if(MainLogoParent.css('display') == 'none'){

            var StickPoint1 = 10;
            var StickPoint2 = 60;

            // Header verkleinern
            if(BrowserDocumentScrollY >= StickPoint1){

                ZusatsLogosWrapperDiv.addClass('hidden');
                MainLogo.addClass('hidden');
                MainLogoSticky.removeClass('hidden');

                MobileLogo.addClass('hidden');
                MobileLogoSticky.removeClass('hidden');

            }
            if(BrowserDocumentScrollY >= StickPoint2){
                TopThemen.addClass('hidden');
                Header.addClass('shadow-md');
                Borderline.addClass('hidden');
            }

            // Header wieder resetten
            if(BrowserDocumentScrollY < StickPoint2){
                TopThemen.removeClass('hidden');
                Header.removeClass('shadow-md');
                Borderline.removeClass('hidden');
            }
            if(BrowserDocumentScrollY < StickPoint1){

                ZusatsLogosWrapperDiv.removeClass('hidden');
                MainLogo.removeClass('hidden');
                MainLogoSticky.addClass('hidden');

                MobileLogo.removeClass('hidden');
                MobileLogoSticky.addClass('hidden');

                setMainPadding();

            }

        }

        // Desktop
        if(MainLogoParent.css('display') == 'block'){

            var StickPoint1 = 10;
            var StickPoint2 = 60;

            // Header verkleinern
            if(BrowserDocumentScrollY >= StickPoint1){

                ZusatsLogosWrapperDiv.addClass('hidden');
                MainLogo.addClass('hidden');
                MainLogoSticky.removeClass('hidden');

            }
            if(BrowserDocumentScrollY >= StickPoint2){
                TopThemen.addClass('hidden');
                //TopThemen.fadeOut(600);
                Header.addClass('shadow-md');
                Borderline.addClass('hidden');
            }

            // Header wieder resetten
            if(BrowserDocumentScrollY < StickPoint2){
                TopThemen.removeClass('hidden');
                //TopThemen.fadeIn(600);
                Header.removeClass('shadow-md');
                Borderline.removeClass('hidden');
            }
            if(BrowserDocumentScrollY < StickPoint1){
                ZusatsLogosWrapperDiv.removeClass('hidden');
                MainLogo.removeClass('hidden');
                MainLogoSticky.addClass('hidden');
                setMainPadding();
            }

        }

    }
    stickyHeader();
    $(window).on('scroll', function () {
        stickyHeader();
    });

    //
    // Offset zu Ankern
    //
    function offsetToAnchor(){

        if(window.location.hash) {
            var hash = window.location.hash.substring(1); //Puts hash in variable, and removes the # character
            var anker = $('div#' + hash);
            var ankerPosition = anker.offset().top;
            var main = $('main');
            //console.log(hash);

            var width = $(window).width();
            //anker.attr('tabindex', '1');
            if(width>=768){
                var offset = 134;
                $('html, body').animate({scrollTop: ankerPosition - offset}, 0);
                //anker.focus();
                //anker.attr('tabindex', '-1');
                //main.css('padding-top',offset + 'px');
                //anker.addClass('anchor-offset');
            }else{
                var offset = 68;
                $('html, body').animate({scrollTop: ankerPosition - offset}, 0);
                //anker.focus();
                //anker.attr('tabindex', '-1');
                //main.css('padding-top',offset + 'px');
                //anker.addClass('anchor-offset');
            }

            /*
            setTimeout(function (){
                anker.removeClass('anchor-offset');
            }, 2000);
            */

            //var topPos = anker.position().top - offset;
            //$(window).scrollTop(parseInt(topPos));

            //setMainPadding();
        }

    }
    offsetToAnchor();

    //
    // Scrolle zu Header
    //
    function scrollToHeader(){
        $('a.jquery__headerLink').on('click', function(event) {
            //event.preventDefault();
            var anker = $($(this).attr('href'));
            var main = $('main');

            // 32=Leertaste, 13=Return, 1=Linke Maustaste
            if (event.which == 13 || event.which == 1) {
                var width = $(window).width();
                if(width>=751){
                    var offset = 134;
                    main.css('padding-top',offset + 'px');
                    anker.addClass('anchor-offset');
                }else{
                    var offset = 68;
                    main.css('padding-top',offset + 'px');
                    anker.addClass('anchor-offset');
                }

                //var scrollTop = $($(this).attr('href')).position().top - offset;
                //$('html, body').animate({ scrollTop }, '300');

                //setMainPadding();

            }

        });
    }
    scrollToHeader();

    //
    // Scrolle zu Seiteninhalt
    //
    function scrollToPageContent(){
        $('a.jquery__contentLink').on('click', function(event) {
            event.preventDefault();
            var anker = $($(this).attr('href'));
            var ankerPosition = anker.offset().top;
            var main = $('main');

            // 32=Leertaste, 13=Return, 1=Linke Maustaste
            if (event.which == 13 || event.which == 1) {
                var width = $(window).width();
                anker.attr('tabindex', '1');
                if(width>=768){
                    var offset = 134;
                    $('html, body').animate({scrollTop: ankerPosition - offset}, 0);
                    anker.focus();
                    anker.attr('tabindex', '-1');
                    //main.css('padding-top',offset + 'px');
                    //anker.addClass('anchor-offset');
                }else{
                    var offset = 68;//68,134
                    $('html, body').animate({scrollTop: ankerPosition - offset}, 0);
                    anker.focus();
                    anker.attr('tabindex', '-1');
                    //main.css('padding-top',offset + 'px');
                    //anker.addClass('anchor-offset');
                }

                //var scrollTop = $($(this).attr('href')).position().top - offset;
                //$('html, body').animate({ scrollTop }, '300');

                //setMainPadding();

            }

        });
    }
    scrollToPageContent();

    //
    // Scrolle zu Top
    //
    function scrollToTop(){
        $('a.jquery__topLink').on('click', function(event) {
            //$('#hilfsnavigation').focus();

            event.preventDefault();
            //console.log($(this).attr('href'));

            // 32=Leertaste, 13=Return, 1=Linke Maustaste
            if (event.which == 13 || event.which == 1) {

                var scrollTop = $('#top').position().top;

                $('html, body').animate({ scrollTop }, '300');

            }

        });
    }
    scrollToTop();

    //
    // Responsive Tabelle im bodytext
    //
    function responsiveTableInBodytext(){
        var BodytextTable = $('div.bund-bodytext-tca table.contenttable');

        if(BodytextTable){
            BodytextTable.wrap('<div class="overflow-x-auto w-full"></div>');
        }

    }
    responsiveTableInBodytext();

    //
    // Linklisten im bodytext
    //
    function linkedListsInBodytext(){
        var Liste = $('div.bund-bodytext-tca li');

        Liste.each(function() {

            var ListString = $(this).html();

            if(ListString.substr(0, 2) == '<a'){

                if($(this).parent('ul').parent('div').hasClass('bund-bodytext-tca')){
                    $(this).css({
                        'list-style-type' : 'none',
                        'margin-left' : '-1.563rem',
                        'margin-right' : '-1.563rem'
                    });
                }else{
                    $(this).css({
                        'list-style-type' : 'none'
                    });
                }

            }

        });
    }
    linkedListsInBodytext();

    //
    // Sprung zur Metanavigation von der ScreenreaderNavigation
    //
    function preSelectFirstChildInMetanavigation(){

        var LinkToDesktopMetanavigation = $('a#linkToDesktopMetanavigation');
        var LinkToMobileMetanavigation = $('a#linkToMobileMetanavigation');
        var MetanavigationDesktop = $('nav#metanavigation');
        var MetanavigationDesktopFirstChild = MetanavigationDesktop.find('a').first();
        var MetanavigationMobile = $('nav#mobilemetanavigation');
        var MetanavigationMobileFirstChild = MetanavigationMobile.find('a').first();

        LinkToDesktopMetanavigation.on('click keypress', function(event) {
            if (event.type === "click" || event.type === "keypress") {
                event.preventDefault();
                // 32=Leertaste, 13=Return, 1=Linke Maustaste
                if (event.which == 32 || event.which == 13 || event.which == 1) {
                    MetanavigationDesktopFirstChild.focus();
                }
            }

        });

        LinkToMobileMetanavigation.on('click keypress', function(event) {
            if (event.type === "click" || event.type === "keypress") {
                event.preventDefault();
                // 32=Leertaste, 13=Return, 1=Linke Maustaste
                if (event.which == 32 || event.which == 13 || event.which == 1) {
                    MetanavigationMobileFirstChild.focus();
                }
            }

        });

    }
    preSelectFirstChildInMetanavigation();

    //
    // Sprung zur Fußnavigation von der ScreenreaderNavigation
    //
    function preSelectFirstChildInFooternavigation(){

        var LinkToFooternavigation = $('a#linkToFooternavigation');
        var Footernavigation = $('nav#footernavigation');
        var FooternavigationFirstChild = Footernavigation.find('a').first();

        LinkToFooternavigation.on('click keypress', function(event) {
            if (event.type === "click" || event.type === "keypress") {
                event.preventDefault();
                // 32=Leertaste, 13=Return, 1=Linke Maustaste
                if (event.which == 32 || event.which == 13 || event.which == 1) {
                    FooternavigationFirstChild.focus();
                }
            }

        });

    }
    preSelectFirstChildInFooternavigation();

    //
    // Decrypt SpamProtected MailAdresses
    //
    /**
     * Decoding helper function
     *
     * @param {number} charCode
     * @param {number} start
     * @param {number} end
     * @param {number} offset
     * @return {string}
     */
    function decryptCharcode(charCode, start, end, offset) {
        charCode = charCode + offset;
        if (offset > 0 && charCode > end) {
            charCode = start + (charCode - end - 1);
        } else if (offset < 0 && charCode < start) {
            charCode = end - (start - charCode - 1);
        }
        return String.fromCharCode(charCode);
    }
    /**
     * Decodes string
     *
     * @param {string} value
     * @param {number} offset
     * @return {string}
     */
    function decryptString(value, offset) {
        var result = '';
        for (var i=0; i < value.length; i++) {
            var charCode = value.charCodeAt(i);
            if (charCode >= 0x2B && charCode <= 0x3A) {
                result += decryptCharcode(charCode,0x2B,0x3A,offset);	/* 0-9 . , - + / : */
            } else if (charCode >= 0x40 && charCode <= 0x5A) {
                result += decryptCharcode(charCode,0x40,0x5A,offset);	/* A-Z @ */
            } else if (charCode >= 0x61 && charCode <= 0x7A) {
                result += decryptCharcode(charCode,0x61,0x7A,offset);	/* a-z */
            } else {
                result += value.charAt(i);
            }
        }
        return result;
    }
    // decrypt spam-protected emails
    function linkTo_UnCryptMailto() {

        var allMailLinks = $('a[data-mailto-vector]');
        allMailLinks.each(function () {
            let token = $(this).attr('data-mailto-token');
            let vector = Math.abs($(this).attr('data-mailto-vector'));
            let raw = decryptString(token,vector);
            //console.log(token);
            //console.log(vector);
            //console.log(raw);
            $(this).on('click keypress', function(event) {
                if (event.type === "click" || event.type === "keypress") {
                    event.preventDefault();
                    // 32=Leertaste, 13=Return, 1=Linke Maustaste
                    if (event.which == 32 || event.which == 13 || event.which == 1) {
                        window.location = raw;

                    }
                }

            });
        });

    }
    linkTo_UnCryptMailto();

    //
    // Dropdown
    //
    $('button.dropdownbutton_selector').each(function () {

        $(this).click(function (e) {
            e.preventDefault();

            var dropdownId = $(this).attr('aria-controls');
            var dropdown = $('div#' + dropdownId);

            // Toggle Dropdown
            if(dropdown.hasClass('hidden')){
                dropdown.removeClass('hidden');
                $(this).attr('aria-expanded','true');
            }else{
                dropdown.addClass('hidden');
                $(this).attr('aria-expanded','false');
            }

        })

    });
    function closeDropDownsWithMouseclickOutsideTheButtons(){

        $(document).mousedown(function(e) {

            var dropDownButtons = $('button.dropdownbutton_selector');

            //1 = Left   mouse button
            //2 = Centre mouse button
            //3 = Right  mouse button

            if (e.which === 1) {
                //console.log('click left');

                dropDownButtons.each(function () {

                    var localbutton = $(this);
                    var localdropdown = $(this).next('div[role=region]');

                    if(localbutton.is(':hover') == false && localdropdown.is(':hover') == false){
                        if($(this).attr('aria-expanded') == 'true'){

                            $(this).next('div[role=region]').addClass('hidden');
                            $(this).attr('aria-expanded','false');

                        }
                    }

                });

            }

        });

    }
    closeDropDownsWithMouseclickOutsideTheButtons();

});